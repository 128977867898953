<template>
  <div>
    <ol class="breadcrumb float-xl-right">
      <li class="breadcrumb-item">
        <router-link :to="{name:'dashboard'}">Dashboard</router-link>
      </li>
      <li class="breadcrumb-item">
        <router-link :to="{name:'users'}">Users</router-link>
      </li>
      <li class="breadcrumb-item"><a href="#">View User</a></li>
    </ol>
    <h1 class="page-header">View User</h1>
    <panel noButton="true" title="View User" variant="panel-default">
      <template v-if="is_loaded">
        <h5 align="center">
          {{ fullname(user) }} ({{ user.role | unslug }})
        </h5>
        <hr>
        <div class="row">
          <div class="col-md-6">
            <table class="table table-striped table-responsive-sm">
              <tbody>
              <tr>
                <td><strong>Email</strong></td>
                <td>{{ user.email }}</td>
              </tr>
              <tr>
                <td><strong>Phone Number</strong></td>
                <td>{{ user.phone_number }}</td>
              </tr>
              <tr>
                <td><strong>ID number</strong></td>
                <td>{{ user.id_number }}</td>
              </tr>
              <tr v-if="user.role === 'relationship_manager'">
                <td><strong>RM Code</strong></td>
                <td>{{ user.user_unique_id }}</td>
              </tr>
              <tr>
                <td><strong>Joined On</strong></td>
                <td>{{ user.created_at }}</td>
              </tr>

              <tr v-if="$can.view($store.getters.getAuthUser, 'suspend-user')">
                <td><strong>Action</strong></td>
                <td>
                  <a
                      v-if="user.active"
                      class="btn btn-warning btn-sm"
                      href="#"
                      @click.prevent="suspend"
                  ><i class="fa fa-ban"></i> Suspend</a>
                  <a
                      v-else
                      class="btn btn-success btn-sm ml-2"
                      href="#"
                      @click.prevent="activate"
                  ><i class="fa fa-sync"></i> Activate</a>
                  <a
                      v-if="deletable"
                      class="btn btn-danger btn-sm ml-2"
                      href="#"
                      @click.prevent="deleteUser"
                  ><i class="fa fa-trash"></i> Delete</a>
                  <router-link
                      v-if="$can.view($store.getters.getAuthUser, 'update-user')"
                      :to="{ name: 'users.edit' , params: { id : this.$route.params.id }}"
                      class="btn btn-primary btn-sm ml-2"
                  >
                   <i class="fa fa-edit"></i> Edit
                  </router-link>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div
              v-if="$can.view($store.getters.getAuthUser, 'create-users')"
              class="col-md-6"
          >
            <div class="card">
              <h5 class="card-header">User Permissions</h5>
              <hr>
              <ol>
                <li
                    v-for="(permission,index) in user.permissions"
                    :key="index"
                >
                  {{ permission.title }}
                </li>
              </ol>
              <br>
              <a
                  class="btn btn-primary btn-sm"
                  href="#"
                  @click.prevent="show_roles_modal = true"
              ><i class="fa fa-edit"></i> Change
                Role</a>
              <modal
                  :show-modal="show_roles_modal"
                  @close="show_roles_modal = false"
              >
                <template v-slot:header>
                  <h5>Change Role</h5>
                </template>
                <div v-html="$error.handle(error)"/>
                <form @submit.prevent="changeRole">
                  <div class="form-group">
                    <select
                        v-model="new_role"
                        class="form-control"
                    >
                      <option
                          v-for="(role,index) in roles"
                          :key="index"
                          :value="role.name"
                      >
                        {{ role.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <button class="btn btn-primary">
                      <i class="fa fa-save"></i> Save
                    </button>
                  </div>
                </form>
              </modal>
            </div>
          </div>
        </div>
      </template>
      <loading v-else/>
    </panel>
  </div>
</template>

<script>
import helper from "@/mixins/helper";
export default {
  mixins: [helper],
  data() {
    return {
      user: {},
      is_loaded: false,
      show_roles_modal: false,
      roles: [],
      new_role: '',
      error: '',
    }
  },
  computed: {
    deletable() {
      return this.user.role !== "customer" && this.$can.view(this.$store.getters.getAuthUser, 'delete-users');
    }
  },
  mounted() {
    this.fetch();
    this.fetchRoles();
  },
  methods: {
    fetch() {
      this.is_loaded = false;
      this.$axios.get('/api/v1/users/' + this.$route.params.id).then(response => {
        this.user = response.data;
        this.is_loaded = true;
      });
    },
    fetchRoles() {
      this.$axios.get('/api/v1/roles').then(response => {
        this.roles = response.data;
      });
    },
    deleteUser() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!'
      }).then((result) => {
        if (result.value) {
          this.$axios.post('/api/v1/users/delete', {
            userid: this.user.user_unique_id
          }).then(() => {
            this.$toastr.s('User deleted successfully.', 'Success');
            this.$router.replace({name: 'users.index'});
          });
        }
      })
    },
    changeRole() {
      this.$axios.post('/api/v1/users/role', {
        userid: this.user.user_unique_id,
        role: this.new_role
      }).then(() => {
        this.$toastr.s('User role updated.', 'Saved');
        this.show_roles_modal = false;
        this.fetch();
      }).catch(error => {
        if (error.response) {
          if (error.response.status === 401) {
            this.error = error.response.data.error;
          } else {
            this.error = error.response.data.message;
          }
        }
      });
    },
    suspend() {
      this.$axios.post('/api/v1/users/suspend', {userid: this.user.user_unique_id}).then(() => {
        this.$toastr.s(this.user.firstname + ' has been suspended');
        this.fetch();
      });
    },
    activate() {
      this.$axios.post('/api/v1/users/activate', {userid: this.user.user_unique_id}).then(() => {
        this.$toastr.s(this.user.firstname + ' has been activated');
        this.fetch();
      });
    }
  }
}
</script>